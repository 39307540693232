
import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button'



const Join = () => {
    const { classes } = useStyles();
    const { t } = useTranslation();

    const googlePlayLink = 'https://play.google.com/store/apps/details?id=com.belltane.dihdah';
    const appStoreLink = 'https://apps.apple.com/app/dihdah/id6499497051';


    return (
        <Grid container maxWidth="xl">
            <Grid item className={classes.container} xs={12} sm={12} md={12}>
                <div className={classes.heroContent}>
                    <Grid container maxWidth="xl">
                        <Grid item xs={12} sm={12} md={12}>
                            <Container className={classes.cardGrid} maxWidth="xl">
                                <div >
                                    <Typography className={classes.bigHeaderTypo}  >
                                        {t('titles:join')}
                                    </Typography>
                                    <Typography className={classes.bigHeadersubtitle}
                                        style={{ color: "#666" }}  >
                                        {t('titles:tagline')}
                                    </Typography>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px' }}>
                                        <a href={googlePlayLink} target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px', textDecoration: 'none' }}>
                                            <Button variant="outlined" className={classes.buttondownload} >
                                                <img src="assets/images/icons/google-play.png" alt="google-play" width="30px" height="30px" style={{ marginRight: "5px" }} />
                                                Download on Google Play
                                            </Button>
                                        </a>
                                        <a href={appStoreLink} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '10px', textDecoration: 'none' }}>
                                            <Button variant="outlined" className={classes.buttondownload} color="primary" >
                                                <img src="assets/images/icons/app-store.png" alt="app-store" width="30px" height="30px" style={{ marginRight: '5px' }} />
                                                Download on the App Store
                                            </Button>
                                        </a>
                                    </div>
                                </div>
                            </Container>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles({ "name": { Join } })((theme) => ({

    buttondownload: {
        fontSize: '15px',
        borderRadius: '50px',
        padding: '15px',
        color: 'white',
        background: theme.palette.primary.main,
        border: '2px solid white',
        "&:hover": {
            color: theme.palette.primary.main,
        }
    },
    content: {
        flex: '1 0 auto',
    },
    heroContent: {

        position: 'relative',
        zIndex: '9',
        display: 'flex',
        alignItems: 'center',
        backgroundSize: 'cover',
        backgroundPosition: '50%',
        backgroundRepeat: 'no-repeat',
        marginTop: '80px',
    },
    container: {

        paddingLeft: '0',
        paddingRight: '0',
        zIndex: '1',
        position: 'relative',
        marginBottom: '50px',
    },
    bigHeaderTypo: {
        fontFamily: 'Roboto',
        background: 'white',
        fontSize: '60px!important',
        lineHeight: '1.2!important',
        color: theme.palette.primary.main,
        marginBottom: '40px!important',
        marginTop: '5%!important',
        textAlign: 'center'
    },
    bigHeadersubtitle: {
        color: theme.palette.primary.main,
        fontFamily: 'Rochester',
        backgroundColor: 'white',
        fontSize: "40px!important",
        textAlign: 'center',

    },

    cardGrid: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(8),
    },

}));

export default Join;
import React from 'react';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';

const PageFooter = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Container>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

          <Link href="/privacypolicy" variant="subtitle1" className={classes.listItemLink}>
            {t('titles:privacypolicy')}
          </Link>
          <Link href="/tos" variant="subtitle1" className={classes.listItemLink} style={{ marginLeft: '20px' }}>
            {t('titles:tos')}
          </Link>
        </div>
           <Typography variant="h4" align="center" className={classes.listItemLink}>
            {'Copyright © '}
            <Link href="https://dihdah.com" className={classes.listItemLink}>
              Belltane LLC
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
       </Container>
    </div>
  );
};

const useStyles = makeStyles({ name: { PageFooter } })((theme) => ({
  root: {
    background: ` #DFF5FF`,
    color: 'black',
    paddingBottom: theme.spacing(2),
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
      paddingLeft: theme.spacing(3),
    },
  },
  listItemLink: {
    fontSize: '16px!important',
    lineHeight: '35px!important',
    transition: 'all .25s cubic-bezier(.645,.045,.355,1)!important',
    textDecoration: 'none!important',
    color: 'black!important',
  },
}));

export default PageFooter;
